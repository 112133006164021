<template>
  <PageWithLayout
    :isBodyMiddle="!isPasswordCheck">
    <CheckPassword
      v-if="!isPasswordCheck"
      :isPasswordCheck.sync="isPasswordCheck"/>
    <template v-else>
      <Search
        :searchDataList.sync="viewModel.searchDataList"
        :searchParams.sync="viewModel.searchParams"
        @onSearch="viewModel.onSearch()"/>
      <Board
        :boardData="viewModel.boardData"
        :searchParams.sync="viewModel.searchParams"
        :dataList="viewModel.dataList"
        :paginationData="viewModel.paginationData"
        :emptyDesc="viewModel.searchDataList.value2 ? '검색결과가 없습니다' : '회원정보를 검색해주세요'"
        @onClickRow="rowId => viewModel.onClickRow(rowId)"
        @onSearch="viewModel.onSearch()">
      </Board>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import CheckPassword from '@lemontree-ai/lemontree-admin-common-front/components/common/checkPassword/CheckPassword';
import Search from '@lemontree-ai/lemontree-admin-common-front/components/common/search/Search';
import Board from '@lemontree-ai/lemontree-admin-common-front/components/common/board/Board';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
// viewModel
import UserListViewModel from '@/views/service/user/viewModel/UserListViewModel'

export default {
  name:'UserLeaveList',
  components:{
    PageWithLayout,
    CheckPassword,
    Search,
    Board,
    Button,
    IconSvg,
  },
  beforeMount(){
    this.viewModel.userAdminType = this.$route.meta.lnbMenuData.id
  },
  watch:{
    isPasswordCheck(){
      if(this.isPasswordCheck){
        this.viewModel.init();
      }
    }
  },
  data(){
    return{
      viewModel: new UserListViewModel(),
      isPasswordCheck:false,
    }
  },
}
</script>

<style scoped>
</style>